import Typography from '@mui/material/Typography'
import * as React from 'react'
import { styled } from '@mui/material/styles'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import TextField from '@mui/material/TextField'
import Card from '@mui/material/Card'
import ListItemButton from '@mui/material/ListItemButton'
import CardContent from '@mui/material/CardContent'
import Box from '@mui/material/Box'
import { makeStyles } from '@mui/styles'
import SearchIcon from '@mui/icons-material/Search'
import InputAdornment from '@mui/material/InputAdornment'
import { useLocation } from '@reach/router'
import { getBankInstitutions, getAuthorisationLink } from '../../api/banks'
import { BankInstitution } from '../../types/banks'
import CardMedia from '@mui/material/CardMedia'
import LinearProgress from '@mui/material/LinearProgress'
import { BanksPaper } from './paperComponents'

const StyledListItem = styled(ListItem)(({ theme }) => ({
    marginBottom: theme.spacing(1),
}))

const SearchTextField = styled(TextField)(({ theme }) => ({
    width: 300,
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(3),
}))

const useStyles = makeStyles({
    bankCard: {
        width: '100%',
        maxHeight: 80,
    },
    countryIcon: {
        fontSize: 48,
    },
})

const listItemImageSx = { width: 100 }

function PickCountry() {
    const [searchValue, setSearchValue] = React.useState('')
    const [bankInstitutions, setBankInstitutions] = React.useState<BankInstitution[]>([])
    const [loading, setLoading] = React.useState(true)
    const classes = useStyles()
    let location = useLocation()

    React.useEffect(() => {
        const countryCode = new URLSearchParams(location.search).get('country_code')
        if (!countryCode) return
        getBankInstitutions(countryCode)
            .then((data) => {
                setBankInstitutions(data)
                setLoading(false)
            })
            .catch((error) => {
                setLoading(false)
                // message.error(error.data.detail)
                // setStatus(false)
            })
    }, [location.search])

    async function authoriseBank(bankId: string) {
        setLoading(true)
        const authorisationLink = await getAuthorisationLink(bankId)
        window.open(authorisationLink)
        setLoading(false)
    }

    return (
        <BanksPaper>
            <Typography variant="overline" component="div" gutterBottom>
                Pick Country
            </Typography>
            <SearchTextField
                onChange={(event) => {
                    setSearchValue(event?.target.value.toLowerCase())
                }}
                id="standard-basic"
                label="Search"
                variant="standard"
                sx={{ marginBottom: loading ? 2 : 5 }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
            />
            {loading && <LinearProgress sx={{ marginBottom: 2 }} />}
            <List sx={{ overflow: 'auto', maxHeight: 500 }}>
                {bankInstitutions
                    .filter(
                        (institution: BankInstitution) =>
                            searchValue.length < 1 ||
                            institution.name.toLowerCase().startsWith(searchValue)
                    )
                    .map((institution: BankInstitution) => {
                        return (
                            <StyledListItem disablePadding key={institution.id}>
                                <Card className={classes.bankCard} sx={{ display: 'flex' }}>
                                    <ListItemButton onClick={() => authoriseBank(institution.id)}>
                                        <CardMedia
                                            component="img"
                                            sx={listItemImageSx}
                                            src={institution.logo_url}
                                            alt="Intitution logo"
                                        />
                                        <CardContent>
                                            <Box>
                                                <Typography component="div" variant="body1">
                                                    {institution.name}
                                                </Typography>
                                            </Box>
                                        </CardContent>
                                    </ListItemButton>
                                </Card>
                            </StyledListItem>
                        )
                    })}
            </List>
        </BanksPaper>
    )
}

export default PickCountry
