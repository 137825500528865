import { Typography } from '@mui/material'
import * as React from 'react'
import PickBank from '../../../../components/Bank/PickBank'
import AppPageWraper from '../../../../components/PageWrappers/AppPageWraper'

function PickbankPage() {
    return (
        <AppPageWraper>
            <Typography variant="h6">Vælg din bank</Typography>
            <PickBank />
        </AppPageWraper>
    )
}

export default PickbankPage
